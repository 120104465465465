var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sq secondary primaryx greyx lighten-1x darken-1" },
    [
      _vm.posterUrl
        ? _c(
            "video",
            {
              ref: "video",
              attrs: {
                preload: "auto",
                poster: _vm.posterUrl,
                nocontrols: "",
                playsinline: ""
              },
              on: { ended: _vm.videoEnded, timeupdate: _vm.updateProgress }
            },
            [
              !_vm.noVideo
                ? _c("source", {
                    attrs: { src: _vm.videoUrl, type: "video/mp4" }
                  })
                : _vm._e(),
              _vm._v("Your system does not support the video tag.\n  ")
            ]
          )
        : _vm._e(),
      _c(
        "div",
        {
          attrs: { id: "overlay", xstyle: "margin:0 14px;" },
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.overlayClick($event)
            }
          }
        },
        [
          _c("div", { staticClass: "bound-row" }, [_vm._t("header")], 2),
          _c("div", { staticStyle: { "xmin-height": "25%" } }, [
            !_vm.noVideo && !_vm.isPlaying
              ? _c(
                  "div",
                  {
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.toggleVideoPlayState($event)
                      }
                    }
                  },
                  [
                    _c("v-img", {
                      staticStyle: { opacity: ".8", margin: "0 auto" },
                      attrs: {
                        "xaspect-ratio": "2/1",
                        src: "/img/play-white.svg",
                        width: 120,
                        xstyle: "opacity: .8; margin: 35%;",
                        coverx: "",
                        xclass: "elevation-7 side-thumb"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.isPlaying
              ? _c(
                  "div",
                  [
                    _c("v-fade-transition", [
                      !_vm.noVideo && _vm.playingControlsVisible
                        ? _c(
                            "div",
                            { staticClass: "playing-controls" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    flat: "",
                                    fab: "",
                                    dark: "",
                                    large: "",
                                    color: "white"
                                  },
                                  on: { click: _vm.toggleVideoPlayState }
                                },
                                [
                                  _c("v-icon", { attrs: { dark: "" } }, [
                                    _vm._v("pause")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  ],
                  1
                )
              : _vm._e()
          ]),
          _c(
            "div",
            {
              staticClass: "bound-row",
              style: [
                { opacity: _vm.isPlaying ? ".31" : "1" },
                { transition: "all ease .3s" }
              ]
            },
            [_vm._t("footer")],
            2
          ),
          _c("v-progress-linear", {
            staticClass: "v-progress",
            staticStyle: { "border-radios": "7px" },
            attrs: {
              round: "",
              "buffer-value": _vm.bufferProgress,
              color: "secondary"
            },
            model: {
              value: _vm.videoProgress,
              callback: function($$v) {
                _vm.videoProgress = $$v
              },
              expression: "videoProgress"
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }