



































































import Vue from "vue";
import { Tracking } from "@/code/tracking";
import EventBus from "@/eventBus";

export default {
  name: "videoPlayer",
  // props: ["videoUrl", "posterUrl"],
  props: {
    videoUrl: { required: true, type: String },
    posterUrl: { required: true, type: String },
    noVideo: { required: false, type: Boolean, default: false }
  },
  computed: {
    videoElm: function(): HTMLVideoElement {
      return <any>this.$refs.video;
    }
    // videoIsPlaying: function(): boolean {
    //   return !this.videoElm.paused;
    // }
  },
  data() {
    return {
      isPlaying: false,
      videoProgress: 0,
      bufferProgress: 0,
      playingControlsVisible: false
    };
  },
  watch: {
    playingControlsVisible: function(newValue: boolean) {
      if (newValue) {
        setTimeout(() => {
          this.playingControlsVisible = false;
        }, 1200);
      }
    }
  },
  created() {},
  mounted() {
    EventBus.$on("a-video-will-play", () => {
      if (this.isPlaying) this.setPlayState(false);
    });
  },
  methods: {
    toggleVideoPlayState: function() {
      this.setPlayState(!this.isPlaying);
    },
    setPlayState: function(play: boolean) {
      let ve = this.videoElm;
      if (play) {
        EventBus.$emit("a-video-will-play");
        ve.play();
        this.isPlaying = true;
        Tracking.video.play(this.videoUrl);

        // btnPlayIcon.classList.remove("fa-play");
        // btnPlayIcon.classList.add("fa-pause");
      } else {
        ve.pause();
        this.isPlaying = false;
        Tracking.video.pause(this.videoUrl);
        // btnPlayIcon.classList.remove("fa-pause");
        // btnPlayIcon.classList.add("fa-play");
      }
    },
    updateProgress: function() {
      let ve: HTMLVideoElement = this.videoElm;
      // Calculate current progress
      let value = Math.round((100 / ve.duration) * ve.currentTime);
      {
        let bValue = Math.round(
          (100 / ve.duration) * ve.buffered.end(ve.buffered.length - 1)
        );
        this.bufferProgress = bValue;
      }
      // When progress % changes
      if (value != this.videoProgress) {
        this.videoProgress = value;

        //Track
        let marks = [5, 10, 30, 45];
        if (marks.some(v => v == value)) {
          console.log("Mark: " + value.toString());
          Tracking.video.crossMark(this.videoUrl, value);
        }
      }
    },
    videoEnded: function() {
      this.isPlaying = false;
      Tracking.video.end(this.videoUrl);
    },
    seek: function(secondsToSlide: number) {
      let ve: HTMLVideoElement = this.videoElm;
      let ticks = 5; // number of frames during fast-forward
      let frms = 10; // number of milliseconds between frames in fast-forward/rewind
      let endtime = ve.currentTime + secondsToSlide; // time to fast-forward/remind to (in seconds)
      // fast-forward/rewind video to end time
      var tdelta = (endtime - ve.currentTime) / ticks;
      var startTime = ve.currentTime;
      for (var i = 0; i < ticks; ++i) {
        (function(j) {
          setTimeout(function() {
            ve.currentTime = startTime + tdelta * j;
          }, j * frms);
        })(i);
      }
      // console.log(   ve);

      // ve.currentTime += secondsToSlide;
    },
    overlayClick() {
      if (this.noVideo) {
        this.$emit("click");
      } else {
        this.playingControlsVisible = !this.playingControlsVisible;
      }
    }
  }
};
